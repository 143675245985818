import { format } from "date-fns";
import React, { useCallback } from "react";
import { sanitizeHtmlOption, validFileExtensions } from "src/utilities/constants";
import sanitizeHtml from "sanitize-html";
import { InfoCard } from "src/pages/FellowshipDetails";
import { AddIcon, AttachmentIcon, ClockIcon, CloseIcon, PaymentIcon, PriceTagIcon, UserCheckIcon } from "src/assets/svg";
import { formatStringDate, isValidFileType, removeItemAtIndex, renderCurrency, renderSuccessMessage } from "src/utilities/functions";
import { useDropzone } from "react-dropzone";
import { ErrorMessage, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { submitProposal } from "src/api";
import { useModalContext } from "src/components/Modal";
import { CustomInput, CustomTextArea, Milestone } from "src/components";

const milestoneExample = { description: "", amount: "", due_date: "" };

const SubmitProposal = ({ fellowship }) => {
	const { setIsOpen } = useModalContext();
	const queryClient = useQueryClient();

	const initialValues = {
		fellowshipId: fellowship?.uuid,
		proposals: null,
		payment_frequency: fellowship?.payment_frequency,
		additional_info: "",
		bid: "",
		milestones: null,
	};
	const validationSchema = Yup.object().shape({
		proposals: Yup.array()
			.min(1, "You must provide at least 1 attachment")
			.typeError("An attachment is required")
			.test("is-valid-type", `Not a valid file type, files must be either ${validFileExtensions.join(", ")}`, (value) => {
				return value?.every((file) => isValidFileType(file && file.name.toLowerCase()));
			})
			.test("is-valid-size", "Max allowed size any file is 10 MB", (value) => {
				return value?.every((file) => file && file.size <= 10 * 1024 * 1024);
			})
			.nullable(),
		payment_frequency: Yup.string()
			.oneOf(["milestone", "one-time"], "Payment Frequency must be one of Milestone or One Time")
			.required("This field is required"),
		additional_info: Yup.string(),
		bid: Yup.number()
			.typeError("This field must be a number")
			.when("payment_frequency", {
				is: (value) => value === "one-time",
				then: (schema) => schema.min(0, "Minimum allowable bid is zero").required("This field is required"),
				otherwise: (schema) => schema,
			}),
		milestones: Yup.array()
			.of(
				Yup.object().shape({
					description: Yup.string().required("This field is required"),
					amount: Yup.number()
						.min(0, "Minimum allowable amount is zero")
						.required("This field is required")
						.typeError("This field must be a number"),
					due_date: Yup.string().required("This field is required"),
				})
			)
			.when("payment_frequency", {
				is: (value) => value === "milestone",
				then: (schema) => schema.required("Milestones are required").min(1, "At least 1 milestone is required"),
				otherwise: (schema) => schema.nullable(),
			}),
	});

	const { mutate, isLoading } = useMutation(submitProposal, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(async () => {
				await queryClient.refetchQueries({ queryKey: ["fellowship", fellowship.uuid], type: "all" });
				setIsOpen(false);
			}),
	});

	return (
		<div>
			<div className="grid min-[950px]:grid-cols-[1fr_max-content] gap-16 border border-[#919BAF66] px-8 py-12 mb-12 rounded-xl">
				<div>
					<h2 className="text-[2.4rem] mb-8 text-headerText font-semibold font-Roobert">Program Details</h2>
					<p className="text-[1.8rem] text-headerText font-medium font-Roobert">{fellowship.title}</p>
					<p className="text-[#778499] text-[1.4rem] mb-8">
						Posted {format(fellowship?.created_at || "2024-06-27T08:45:05.000000Z", "MMM dd, yyyy")}
					</p>
					<div
						className="*:font-medium *:text-[1.4rem] *:text-bodyText2 *:leading-[1.7] truncate-overflow [--max-lines:6] [--line-height:2.38rem] mb-10"
						dangerouslySetInnerHTML={{
							__html: sanitizeHtml(fellowship?.description, sanitizeHtmlOption),
						}}
					/>
					<p className="text-[1.8rem] text-headerText font-medium font-Roobert mb-6">Skills and Expertise</p>
					<div className="flex gap-6 max-w-[550px] flex-wrap">
						{fellowship?.skills_required?.map((skill, index) => (
							<p key={index} className="text-[1.4rem] rounded-full font-medium text-bodyText px-6 py-4 bg-[#EFEFEF]">
								{skill}
							</p>
						))}
					</div>
				</div>
				<div className="min-[950px]:w-[clamp(200px,40vw,230px)] grid gap-10 max-[950px]:grid-cols-[repeat(auto-fill,minmax(150px,1fr))] h-fit">
					<InfoCard
						text={formatStringDate(fellowship?.closing_date || "2024-07-04")}
						icon={<ClockIcon />}
						information="Application Closes"
					/>
					<InfoCard text={renderCurrency(fellowship?.project_budget)} icon={<PriceTagIcon />} information="Budget" />
					<InfoCard text={fellowship?.payment_frequency?.replace("-", " ")} icon={<PaymentIcon />} information="Payment Frequency" />
					<InfoCard text={fellowship?.level_of_expertise} icon={<UserCheckIcon />} information="Expertise" />
				</div>
			</div>
			<h3 className="text-[2rem] mb-12 text-headerText font-semibold font-Roobert">Your Proposal Terms</h3>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values) => {
					const data = { ...values };

					if (values.payment_frequency === "milestone") {
						data.total_amount = values.milestones?.reduce((acc, milestone) => acc + +milestone.amount, 0);
					}

					mutate(data);
				}}
			>
				{({ handleSubmit, handleChange, values, setFieldValue, errors }) => (
					<form onSubmit={handleSubmit} className="grid gap-16">
						<div>
							<p className="text-[1.8rem] text-[#101928] font-bold mb-6">How do you want to be paid?</p>
							<div className="grid gap-8">
								<div className="flex items-start gap-3">
									<input
										type="radio"
										checked={values.payment_frequency === "milestone"}
										className="w-[20px] h-[20px] accent-primary checked:bg-primary"
										onChange={(event) => {
											handleChange(event);
											setFieldValue("milestones", [milestoneExample]);
										}}
										value="milestone"
										name="payment_frequency"
										id="payment_frequency-milestone"
									/>
									<label htmlFor="payment_frequency-milestone" className="flex flex-col gap-1 cursor-pointer">
										<span className="text-[#101928] text-[1.6rem] font-semibold">Milestone based</span>
										<span className="text-[#818B9C] text-[1.4rem]">
											Project will be divided into small segments called milestones. You will be paid once milestones are
											completed and approved by the creator
										</span>
									</label>
								</div>
								<div className="flex items-start gap-3">
									<input
										type="radio"
										checked={values.payment_frequency === "one-time"}
										className="w-[20px] h-[20px] accent-primary checked:bg-primary"
										onChange={(event) => {
											handleChange(event);
											setFieldValue("milestones", null);
										}}
										value="one-time"
										name="payment_frequency"
										id="payment_frequency-one-time"
									/>
									<label htmlFor="payment_frequency-one-time" className="flex flex-col gap-1 cursor-pointer">
										<span className="text-[#101928] text-[1.6rem] font-semibold">One Time</span>
										<span className="text-[#818B9C] text-[1.4rem]">
											You will get your payment when the entire project has been completed and approved by the creator.
										</span>
									</label>
								</div>
							</div>
						</div>
						{values.payment_frequency === "milestone" ? (
							<div>
								<p className="text-[1.8rem] text-[#101928] font-bold mb-6">Input your milestones below:</p>
								<div className="grid gap-8 mb-16">
									{values?.milestones?.map((milestone, index) => (
										<Milestone milestone={milestone} key={index} index={index} />
									))}
									<button
										onClick={() => setFieldValue("milestones", [...(values?.milestones ?? []), { ...milestoneExample }])}
										type="button"
										className="text-primary text-[1.6rem] font-medium w-fit flex gap-4 items-center mt-6"
									>
										<AddIcon /> Add Milestone
									</button>
								</div>
								<div className="flex items-center justify-between py-[2.5rem] border-y border-[#919BAF66]">
									<div>
										<p className="text-[#101928] text-[1.8rem] font-bold">Total:</p>
										<p className="text-[#818B9C] text-[1.4rem]">
											This is the price the program creator will see. It includes all the milestones together.
										</p>
									</div>
									<p className="text-primary font-semibold text-[1.6rem]">
										{renderCurrency(values.milestones?.reduce((sum, current) => sum + parseFloat(current.amount || 0), 0))}
									</p>
								</div>
							</div>
						) : (
							<div>
								<CustomInput
									label="Bid"
									value={values.bid}
									onChange={handleChange}
									placeholder="N1,000,000"
									name="bid"
									type="text"
									inputMode="numeric"
									innerContainerClassName="!px-6"
									containerClassName="max-w-[300px]"
								/>
								<p className="text-[#434C5B] text-[1.2rem] mt-3">Propose an amount to be paid for the entire project</p>
							</div>
						)}
						<p className="font-bold text-[1.8rem] text-headerText">Attachments and other Information</p>
						<CustomTextArea
							name="additional_info"
							onChange={handleChange}
							value={values.additional_info}
							placeholder="Enter message"
							rows={6}
							className="py-6"
							label="Other Information"
						/>
						<Dropzone name="proposals" />
						<div className="flex items-center justify-end">
							<button className="cancel-btn" type="button" onClick={() => setIsOpen(false)}>
								Cancel
							</button>
							<button className="px-6 py-4 primary-btn btn-loader" disabled={isLoading}>
								Submit proposal
							</button>
						</div>
					</form>
				)}
			</Formik>
		</div>
	);
};

const Dropzone = ({ name }) => {
	const { setFieldValue, values } = useFormikContext();

	const onDrop = useCallback(
		(acceptedFiles) => {
			const files = acceptedFiles?.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }));
			setFieldValue(name, [...(values.proposals ?? []), ...files]);
		},
		[name, setFieldValue, values.proposals]
	);

	const { getRootProps, getInputProps, open } = useDropzone({
		onDrop,
		multiple: true,
		noClick: true,
		accept: {
			"text/*": [".csv", ".txt"],
			"application/msword": [".doc"],
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
			"application/vnd.ms-powerpoint": [".ppt"],
			"application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
			"application/pdf": [".pdf"],
			"application/vnd.ms-excel": [".xls"],
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
		},
	});

	return (
		<div>
			<p className="font-medium text-[1.6rem] text-bodyText2 mb-4">Attachments</p>
			<div className="border border-[919BAF1A] rounded-xl mb-2 px-16 py-16 flex items-center justify-center flex-col" {...getRootProps()}>
				<input {...getInputProps()} aria-label="input-file" />
				{!!values?.proposals?.length && (
					<div className="flex flex-wrap justify-center gap-5 mb-8">
						{values?.proposals?.map((file, index) => (
							<div
								key={index}
								className="text-[1.4rem] rounded-full font-medium text-bodyText px-3 py-1 bg-[#EFEFEF] flex items-center gap-3 z-50"
							>
								<AttachmentIcon className="w-[15px] h-[15px]" />
								{file.name}
								<button
									className="p-1 rounded-md hover:bg-red-500 flex items-center justify-center hover:*:text-white"
									type="button"
									onClick={(event) => {
										event.stopPropagation();
										setFieldValue(name, removeItemAtIndex(values.proposals, index));
									}}
								>
									<CloseIcon className="w-[12px] h-[12px]" />
								</button>
							</div>
						))}
					</div>
				)}
				<p className="text-[1.6rem] text-bodyText font-semibold max-w-[280px] text-center mb-6 mt-3">
					Drag or{" "}
					<button type="button" onClick={open} className="underline text-primary">
						upload
					</button>{" "}
					files
				</p>
				<p className="text-[1.4rem] text-bodyText max-w-[410px] text-center mb-6">
					You may attach files under the size of 10MB each. This includes documents containing your proposal for this program and any other
					necessary documents.
				</p>
			</div>
			<ul className="flex items-center gap-x-8 gap-y-1 flex-wrap text-[1.4rem] list-inside list-disc text-bodyText">
				<li className="marker:!m-0">Maximum file size: 10MB</li>
			</ul>
			<ErrorMessage name={name} component="div" className="block mt-2 text-[1.2rem] text-red-500" />
		</div>
	);
};

export default SubmitProposal;
